import PolicijaLogo from "../src/assets/partner-logos/policija.jpg";
import LMSLogo from "../src/assets/partner-logos/lms.jpg";
import PVILogo from "../src/assets/partner-logos/pvi.jpg";
import SkautaiLogo from "../src/assets/partner-logos/skautai.jpg";
import TILogo from "../src/assets/partner-logos/transparency.jpg";
import LIJOTLogo from "../src/assets/partner-logos/lijot.jpg";
import MailerliteLogo from "../src/assets/partner-logos/mailerlite.png";
import CityBeeLogo from "../src/assets/partner-logos/city-bee.png";
import MaximaLogo from "../src/assets/partner-logos/maxima.png";
import ManNeDzinLogo from "../src/assets/partner-logos/man-ne-dzin.jpg";
import ManoBalsasLogo from "../src/assets/partner-logos/mano-balsas.jpg";
import ManoSeimasLogo from "../src/assets/partner-logos/mano-seimas.jpg";
import AvilysLogo from "../src/assets/partner-logos/avilys.jpg";
import TSPMILogo from "../src/assets/partner-logos/tspmi.jpg";
import VRKLogo from "../src/assets/partner-logos/vrk.jpg";
import ZinauKaRenkuLogo from "../src/assets/partner-logos/zinau-ka-renku.jpg";
import LPExpressLogo from "../src/assets/partner-logos/lp-express.png";
import BoltLogo from "../src/assets/partner-logos/bolt.png";
import MEGALogo from "../src/assets/partner-logos/mega.png";
import CUPLogo from "../src/assets/partner-logos/cup.png";
import RetailMediaBalticLogo from "../src/assets/partner-logos/retail-media-baltic.png";
import PikselLogo from "../src/assets/partner-logos/piksel.png";
import ACMLogo from "../src/assets/partner-logos/acm.png";
import VVTLogo from "../src/assets/partner-logos/vvt.png";
import RYOLogo from "../src/assets/partner-logos/ryo.png";
import MinijaLogo from "../src/assets/partner-logos/minija.png";
import BusturasLogo from "../src/assets/partner-logos/busturas.png";

export const PARTNERS = [
  {
    key: "policija",
    logo: PolicijaLogo,
    url: "https://policija.lrv.lt/"
  },
  {
    key: "citybee",
    logo: CityBeeLogo,
    url: "https://www.citybee.lt/"
  },
  {
    key: "lpexpress",
    logo: LPExpressLogo,
    url: "https://lpexpress.lt/"
  },
  {
    key: "bolt",
    logo: BoltLogo,
    url: "https://bolt.eu/"
  },
  {
    key: "mega",
    logo: MEGALogo,
    url: "https://www.mega.lt/"
  },
  {
    key: "cup",
    logo: CUPLogo,
    url: "https://www.cup.lt/"
  },
  {
    key: "rmb",
    logo: RetailMediaBalticLogo,
    url: "https://retailmediabaltic.com/"
  },
  {
    key: "piksel",
    logo: PikselLogo,
    url: "https://piksel.lt/"
  },
  {
    key: "acm",
    logo: ACMLogo,
    url: "https://www.acm.lt/"
  },
  {
    key: "vvt",
    logo: VVTLogo,
    url: "https://www.vilniausviesasistransportas.lt/"
  },
  {
    key: "ryo",
    logo: RYOLogo,
    url: "https://ryo.lt/"
  },
  {
    key: "minija",
    logo: MinijaLogo,
    url: "https://www.gargzdukinas.lt/"
  },
  {
    key: "busturas",
    logo: BusturasLogo,
    url: "https://www.busturas.lt/"
  },
  {
    key: "maxima",
    logo: MaximaLogo,
    url: "https://www.maxima.lt/"
  },
  {
    key: "lms",
    logo: LMSLogo,
    url: "https://www.moksleiviai.lt/"
  },
  {
    key: "pvi",
    logo: PVILogo,
    url: "http://www.civitas.lt/"
  },
  {
    key: "skautai",
    logo: SkautaiLogo,
    url: "https://skautai.lt/"
  },
  {
    key: "ti",
    logo: TILogo,
    url: "https://www.transparency.lt/"
  },
  {
    key: "lijot",
    logo: LIJOTLogo,
    url: "https://lijot.lt/"
  },
  {
    key: "dzin",
    logo: ManNeDzinLogo,
    url: "https://www.facebook.com/mannedzin/"
  },
  {
    key: "mailerlite",
    logo: MailerliteLogo,
    url: "https://www.mailerlite.com/"
  },
  {
    key: "balsas",
    logo: ManoBalsasLogo,
    url: "http://www.manobalsas.lt/"
  },
  {
    key: "seimas",
    logo: ManoSeimasLogo,
    url: "http://manoseimas.lt/"
  },
  {
    key: "avilys",
    logo: AvilysLogo,
    url: "http://www.nvoavilys.lt/"
  },
  {
    key: "tspmi",
    logo: TSPMILogo,
    url: "http://www.tspmi.vu.lt/"
  },
  {
    key: "vrk",
    logo: VRKLogo,
    url: "https://www.vrk.lt/"
  },
  {
    key: "renku",
    logo: ZinauKaRenkuLogo,
    url: "https://www.zinaukarenku.lt/"
  }
];
